import parse from "html-react-parser";
import H2TrapeziumBG from "../../common/TrapeziumBackground/H2TrapeziumBG";
import {IAboutUsComponentProps} from '../../../lib/types/components';
import CustomImageComponent from "../../common/CustomImage/CustomImageComponent";
import useIsMobile from "../../../lib/customHooks/isMobile";
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./AboutUsComponentV2.module.css"

const AboutUsComponentV2 = (props: IAboutUsComponentProps) => {
    const {isMobileDevice} = useIsMobile()
    const data = props.aboutUsData;

    return (
        data && Object.keys(data).length ?
            <H2TrapeziumBG uniqueContainerId={"aboutUs_container"}>
                <div className={multipleClassName("container-fluid", styles.aboutUsParentContainer)}>
                    <CustomImageComponent
                        src={data.img2 ? data.img2 : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/about_winzo_bg2.webp"}
                        layout={"fill"}
                        alt={"about_winzo_bg2"}
                        objectFit={"cover"}/>

                    <CustomImageComponent
                        src={data.img1 ? data.img1 : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/about_winzo_bg1.webp"}
                        layout={"fill"}
                        alt={"about_winzo_bg2"}
                        objectPosition={"bottom"}
                        objectFit={isMobileDevice ? "contain" : "cover"}/>
                    <div className={multipleClassName('container', styles.aboutUsMainContainer)}>
                        <div className={styles.headingParent}>
                            <h2 className={styles.heading}>{data.heading}</h2>
                        </div>
                        <div className={multipleClassName("row", styles.paraParentContainer)}>
                            <div className={multipleClassName("col-9", "col-md-8", styles.paraContainer)}
                                 style={{backgroundColor: data.overlayColor}}>
                                {data.para?.map((item, index) => {
                                    return (
                                        <div className={styles.aboutUsPara} key={"data.para_" + index}>
                                            {parse(item)}
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>

                    </div>
                </div>
                <hr className={styles.hr} />
            </H2TrapeziumBG> : <></>
    )
}

export default AboutUsComponentV2;
